import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Theme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import { clone } from '../../../core/utils';
import { formatDate } from '../../../components/renderers';
import { create, isValid } from '../../../components/form';
import { extendedstyles } from '../../appstyles';

const extrastyles = (theme: Theme) => ({
  reqform: {
    //
  },
});
const styles = extendedstyles(extrastyles);

class InfoPanel extends Component<any, any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props: any) {
    super(props);
    this.state = {
      source: {},
      preview: false,
      valid: false,
      count: 0,
    };
  }

  componentDidMount() {
    this.props.setValid(this.state.valid);
  }

  formconfig = () => {
    //combo.inputLabelProps => cilp
    const cbip = { InputLabelProps: { shrink: true } };
    const config = {
      service: {
        name: 'dataform',
        title: 'Data Form',
        eform: [
          //:nam,:ati,:aci,:ayi,:mbn,:cbu,:cbp,:cbc,:cba,:lci,:dtl,:sts'
          {
            id: 'nam',
            label: 'Entity Name',
            type: 'tf',
            req: 1,
            ops: { helperText: 'Enter Name', pattern: '[a-zA-Z ]+' },
          },
          {
            id: 'eti',
            label: 'Entity Type',
            type: 'fa',
            req: 1,
            ops: { helperText: 'Select Type', ...cbip },
            cp: { t: 'bcs', s: 'entitytype' },
          },
          //{ id:'agc',label:'Agent Code',type:'tf',req:1,ops:{ helperText:'Enter Code',pattern:"[a-zA-Z ]+" } },
          {
            id: 'adr',
            label: 'Address',
            type: 'tf',
            req: 1,
            ops: { helperText: 'Enter Address', pattern: '[a-zA-Z ]+' },
          },
          {
            id: 'ct1',
            label: 'Company code',
            type: 'tf',
            req: 1,
            ops: {
              helperText: 'Enter Company Registration Code',
              pattern: '[GHS0-9]+',
            },
          },
          // { id:'rgi',label:'Region',type:'fa',req:0,ops:{helperText:'Select Region',...cbip},cp:{t:'bcs',s:'region'},chs:['dsi','lci']},
          // { id:'dsi',label:'District',type:'fa',req:0,ops:{helperText:'Select District',...cbip},cp:{t:'bcs',s:'district'},chs:['lci'],pns:['rgi']},
          // { id:'lci',label:'Location',type:'fa',req:0,ops:{helperText:'Select Location',...cbip},cp:{t:'bcs',s:'location'},pns:['dsi']},
          // { id:'dtl',label:'Details',type:'ta',req:0,ops:{helperText:'Enter Additional Details'}},
        ],
      },
      create: true,
      actions: { add: 'Add' },
      panels: { add: 'simple' },
    };
    return config;
  };

  setValid = (data: any) => {
    const sconfig = this.formconfig();
    const { mode } = this.props;
    const valid = ['del', 'delete'].includes(mode)
      ? true
      : isValid(sconfig, data, mode);
    //console.log('valid:',valid);
    //this.setState({valid});

    //const valid = (!!newdata.nam && !!newdata.agc && !!newdata.mbn && !!newdata.lci);
    //const valid = (!!data.nam && !!data.agc && !!data.mbn && !!data.lci);
    //console.log('valid:',valid);
    this.props.setValid(valid);
  };

  onChange = (name: any) => (value: any) => {
    const { data } = this.props;
    const newdata = { ...data.data, [name]: value };
    this.setValid(newdata);
    this.props.setItem('data', 'data', newdata);
  };

  makeform = () => {
    const config = this.formconfig();

    const { record, extras, classes } = this.props;
    const sconfig = clone(config);
    const sdata = sconfig.service;
    const mode = 'add';
    const formatters = { dob: formatDate };
    const onChange = this.onChange.bind(this);
    const form = create(
      mode,
      sdata,
      record,
      formatters,
      onChange,
      classes,
      extras
    );
    return form;
  };

  render() {
    //const {data,classes} = this.props;
    const form = this.makeform();
    return form;
  }
}

InfoPanel.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(InfoPanel);
