import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Theme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import classNames from 'classnames';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
// import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
//import {saveAs} from 'file-saver';
import { clone } from '../../../core/utils';
import {
  formatDate,
  formatGender,
  formatStatus,
} from '../../../components/renderers';
import { createItemList, create, isValid } from '../../../components/form';
import { extendedstyles } from '../../appstyles';

const extrastyles = (theme: Theme) => ({
  formcontainer: {
    overflow: 'scroll',
  },
  hide: {
    display: 'none',
  },
  spinner: {
    backgroundImage: 'url(assets/img/loading.gif)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    margin: 'auto',
    width: 80,
    height: 80,
  },
  minicolumn: {
    width: 200,
  },
});
const styles = extendedstyles(extrastyles);

class SendPanel extends Component<any, any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props: any) {
    super(props);
    this.state = {
      source: {},
      preview: false,
      valid: true,
      count: 0,
    };
  }

  componentDidMount() {
    this.props.setValid(this.state.valid);
    //this.drawCard();
  }

  formconfig = () => {
    const config = {
      service: {
        name: 'review',
        title: 'Review Information',
        eform: [
          //:nam,:ati,:aci,:ayi,:mbn,:cbu,:cbp,:cbc,:cba,:lci,:dtl,:sts'
          { id: 'fnm', label: 'First Name', type: 'rf', col: 1, ops: {} },
          { id: 'snm', label: 'Surname', type: 'rf', col: 1, ops: {} },

          { id: 'unm', label: 'User Name', type: 'rf', col: 1, ops: {} },
          { id: 'adr', label: 'Address', type: 'rf', col: 1, ops: {} },
          { id: 'eti', label: 'Entity', type: 'rf', col: 2, req: 0, ops: {} },
          { id: 'roi', label: 'Role', type: 'rf', col: 2, req: 0, ops: {} },

          {
            id: 'ct1',
            label: 'Mobile No',
            type: 'rf',
            col: 1,
            req: 0,
            ops: {},
          },
          { id: 'nam', label: 'Account Name', type: 'rf', col: 2, ops: {} },
          {
            id: 'shc',
            label: 'Account Short Code',
            type: 'rf',
            col: 2,
            ops: {},
          },
          { id: 'cui', label: 'Currency', type: 'rf', col: 2, req: 0, ops: {} },

          {
            id: 'bal',
            label: 'Billing Balance',
            type: 'rf',
            col: 2,
            req: 0,
            ops: {},
          },
          {
            id: 'amk',
            label: 'Access Mask',
            type: 'rf',
            col: 2,
            req: 0,
            ops: {},
          },
          {
            id: 'dsc',
            label: 'Description',
            type: 'rf',
            col: 2,
            req: 0,
            ops: {},
          },
        ],
      },
      create: true,
      actions: { view: 'View' },
      panels: { view: 'column' },
    };
    return config;
  };

  mkColumns = (items: any, columns: any) => {
    const fields: any = {};
    console.log('items:', items, 'columns:', columns);
    items.map((item: any) => {
      let id = columns[item.id] || 1;
      let key = `col${id}`;
      if (!fields[key]) fields[key] = [];
      fields[key].push(item);
      return true;
    });
    return fields;
  };

  makeform = () => {
    const config = this.formconfig();
    const { record, classes, extras } = this.props;
    console.log('record:', record);
    const sconfig = clone(config);
    const sdata = sconfig.service;
    const mode = 'view';
    const formatters = { dob: formatDate, sts: formatStatus };
    const onChange = this.onChange.bind(this);
    extras.options = { fullWidth: false };
    const form = create(
      mode,
      sdata,
      record,
      formatters,
      onChange,
      classes,
      extras
    );
    //const formitems = createItemList(mode,sdata,record,formatters,onChange, extras);
    return form;
  };

  onChange = (name: any) => (value: any) => {};

  makeview = () => {
    const { data, classes, ...others } = this.props;
    console.log('send.dat', data);
    console.log('send.others', others);
    const { data: record } = data;
    const config = this.formconfig();
    const { eform } = config.service;
    const rows = eform.reduce((acc: any, rec: any) => {
      const { id, label } = rec;
      let row: any = { id, label, value: record[id] };
      acc.push(row);
      return acc;
    }, []);
    return (
      <div className={classes.viewroot}>
        <Paper className={classes.viewpaper}>
          <Table
            className={classes.viewtable}
            size='small'
            aria-label='a dense table'
          >
            {/* <TableHead>
              <TableRow>
                <TableCell>Parameter</TableCell>
                <TableCell align="right">Value</TableCell>
              </TableRow>
            </TableHead> */}
            <TableBody>
              {rows.map((row: any) => (
                <TableRow key={row.id}>
                  <TableCell component='th' scope='row'>
                    {row.label}
                  </TableCell>
                  <TableCell align='left'>{row.value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </div>
    );
  };

  render() {
    const { loading, classes } = this.props;
    //const form = this.makeform();
    const form = this.makeview();
    return (
      <div className={classNames(classes.container, classes.formcontainer)}>
        <div className={loading ? classes.spinner : classes.hide}></div>
        {form}
      </div>
    );
  }
}

SendPanel.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(SendPanel);
