import React, { Component } from 'react';
// import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Theme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Radio';
import GridHeader from './GridHeader';
import GridToolbar from './GridToolbar';
import GridPagingActions from './GridPagingActions';
import { gridSort } from './GridSorter';
import { extendedstyles } from '../../app/appstyles';

const extrastyles = (theme: Theme): any => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  cell: {
    // borderLeftStyle: 'solid',
    // borderLeftColor: theme.palette.grey['500'],
    // borderLeftWidth: '1px',
    // paddingLeft: '2px',
  },
});

const styles = extendedstyles(extrastyles);

class GridPanel extends Component<any, any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props: any) {
    super(props);
    this.state = {
      order: this.props.order || 'asc',
      orderBy: this.props.orderBy, //'calories',
      selected: [],
      cols: props.rows.map((row: any) => row.id),
      rows: props.rows, //this.props.rows,
      data: props.data, //this.props.data,
      page: props.page || 0, //this.props.page || 0,
      total: props.recordCount,
      rowsPerPage: props.rowsPerPage || 10, //this.props.rowsPerPage||5,
      selectedRecord: null,
      selectedIndex: null,
      actions: [],
    };
  }

  onColumnSort = (event: any, property: any) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  onRowSelectAll = (event: any) => {
    if (this.state.selectedIndex) {
      //(event.target.checked) {
      this.setState({
        selected: [],
        selectedRecord: null,
        selectedIndex: null,
      });
    }
  };

  onRowSelect = (event: any, id: any) => {
    event.preventDefault();
    if (this.state.selectedIndex === id) {
      return;
    }
    //RSM
    const rec = this.props.data[id - 1];
    const { actions } = this.props;
    const actype = typeof actions;
    const gridactions = Array.isArray(actions)
      ? actions
      : actype === 'function'
      ? actions(id, rec)
      : actype === 'object'
      ? Object.keys(actions)
      : actype === 'string'
      ? [actions]
      : [];
    //
    this.setState({
      selected: [id],
      selectedRecord: rec,
      selectedIndex: id,
      actions: gridactions,
    });
  };

  onPageChange = (event: any, page: any) => {
    this.setState({ page });
  };

  onRowsPerPageChange = (event: any) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  //RSM
  isSelected = (id: any) => this.state.selectedIndex === id;

  render() {
    const {
      search,
      query,
      title,
      sdata,
      rows,
      data,
      formatters,
      actionSet,
      onAction,
      handleSelect,
      refresh,
      setColumns,
      filterColumns,
      classes,
    } = this.props;
    const {
      recordCount,
      pageMode,
      page: rempage,
      onPageChange,
      onRowsPerPageChange,
    } = this.props;
    const {
      selected,
      selectedRecord,
      actions,
      order,
      orderBy,
      rowsPerPage,
      page: locpage,
      cols,
    } = this.state;

    const isRemote = pageMode === 'remote';
    const page = isRemote ? rempage : locpage;
    const rowCount = isRemote ? recordCount : data.length;
    const emptyRows =
      rowsPerPage - Math.min(rowsPerPage, rowCount - page * rowsPerPage);
    const fm = formatters || {};
    const frows = filterColumns(rows, cols);
    //console.log('searchmode:',search,',query:',query);
    const sorted = gridSort(data, order, orderBy);
    const tabdata = isRemote
      ? sorted
      : sorted.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    return (
      <Paper className={classes.root}>
        <GridToolbar
          title={title}
          numSelected={selected.length}
          selectedRecord={selectedRecord}
          handleSelect={handleSelect}
          onAction={onAction}
          refresh={refresh}
          actions={actions}
          actionSet={actionSet}
          setColumns={setColumns}
          cols={rows}
          fcols={frows}
          sdata={sdata}
        />
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby='tableTitle'>
            <GridHeader
              cols={frows}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={this.onRowSelectAll}
              onRequestSort={this.onColumnSort}
              rowCount={rowCount}
            />
            <TableBody>
              {tabdata.map((n: any) => {
                const isSelected = this.isSelected(n.id);
                return (
                  <TableRow
                    hover
                    onClick={(event) => this.onRowSelect(event, n.id)}
                    role='checkbox'
                    aria-checked={isSelected}
                    tabIndex={-1}
                    key={n.id}
                    selected={isSelected}
                  >
                    <TableCell key='rsm' padding='checkbox'>
                      <Checkbox checked={isSelected} />
                    </TableCell>
                    {frows.map((col: any) => (
                      <TableCell
                        key={col.id}
                        padding='none'
                        className={classes.cell}
                      >
                        {fm[col.id] ? fm[col.id](n[col.id], n) : n[col.id]}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{ height: 49 * emptyRows }}
                  className={classes.cell}
                >
                  <TableCell colSpan={frows.length + 1} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan={frows.length + 1}
                  count={recordCount}
                  //count={data.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={isRemote ? onPageChange : this.onPageChange}
                  onRowsPerPageChange={
                    isRemote ? onRowsPerPageChange : this.onRowsPerPageChange
                  }
                  //ActionsComponent={GridPagingActions}
                  //ActionsComponent={'span'}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </div>
      </Paper>
    );
  }
}

GridPanel.propTypes = {
  filterColumns: PropTypes.func,
  classes: PropTypes.object.isRequired,
  rowsPerPage: PropTypes.number,
  data: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  orderBy: PropTypes.string.isRequired,
};

export default withStyles(styles)(GridPanel);
