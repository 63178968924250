import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Theme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import { apipost, bizpost } from '../../../ajax';
import { BaseCard } from '../../../components/wizard';
import SendPanel from './SendPanel';
import { extendedstyles } from '../../appstyles';

const extrastyles = (theme: Theme) => ({
  progress: {
    margin: theme.spacing(0, 2),
  },
  spinner: {
    backgroundImage: 'url(lib/img/loading.gif)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    margin: 'auto',
    width: 80,
    height: 80,
  },
  note: {
    margin: theme.spacing(1),
  },
  error: {
    color: '#ff0000',
  },
});
const styles = extendedstyles(extrastyles);

class SendCard extends Component<any, any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props: any) {
    super(props);
    this.props.deb.log('SendCard');
    this.state = {
      next: false,
      percent: 0,
      fwopen: false,
      error: false,
      status: 'Saving',
      loading: false,
    };
  }

  handleProgress = (ev: any, perc: any) => {
    this.setState({ percent: perc });
  };

  // onSubmit = async() => {
  //   console.log('starting onSubmit... ');
  //   const {code,service,mode,sconfig,record,onResponse,ft,ms,mt} = this.props;
  //   const {url,endpoint} = sconfig || {};
  //   const {formData} = this.state;
  //   //console.log('onsubmit.formdata:',formData);
  //   try{
  //     let s = service;
  //     let a = mode;
  //     let recs = (mode==='delete')?{rid:+(record.rid),stp:record.stp}:record;
  //     let params = Object.assign(recs,formData,{s,a,ft,ms,mt});
  //     console.log('service request ',params);
  //     this.startLoading();
  //     var response;
  //     if(endpoint==='api')
  //       response = await apipost(url,code,params);
  //     else
  //       response = await bizpost(params);
  //     //console.log('service response ',response);

  //     if (response.success){
  //       this.props.showNotification(response.sm,'success',response.st)
  //       onResponse(true,response);
  //     }
  //     else if(response.failure){
  //       if (response.en===60){
  //       }
  //       else{
  //         this.props.showNotification(response.message,response.type||'error',response.title)
  //       }
  //     }
  //   }
  //   catch(error){
  //     console.log('submitr.error:',error);
  //     this.props.showNotification(error.message||"request failed",error.type||'warn',error.title||'Update Error');

  //   }
  //   finally{
  //     this.stopLoading();
  //   }
  // }

  startLoading = () => {
    this.setState({ loading: true });
  };

  stopLoading = () => {
    this.setState({ loading: false });
  };

  //this method is designed for basecard.handleSend
  doSubmit = async () => {
    const { classes, theme, showNotification, ...extraProps } = this.props; //fmtUI, logo, title, switchFn,
    const { record, data, extras, setItem } = this.props;
    const { data: formdata } = data;

    const { code, service, mode, sconfig, onResponse, ft, ms, mt } = this.props;
    const { url, endpoint } = sconfig || {};
    //const {formData} = this.state;
    //console.log('onsubmit.formdata:',formData);
    try {
      let s = service;
      let a = mode;
      //let recs = (mode==='delete')?{rid:+(record.rid),stp:record.stp}:record;
      let params = Object.assign(record, formdata, { s, a, ft, ms, mt });
      console.log('service request ', params);
      this.startLoading();
      var response;
      if (endpoint === 'api') response = await apipost(url, code, params);
      else response = await bizpost(params);
      //console.log('service response ',response);

      if (response.success) {
        showNotification(response.sm, 'success', response.st);
        //onResponse(true,response);
        return response;
      } else if (response.failure) {
        if (response.en === 60) {
        } else {
          showNotification(
            response.message,
            response.type || 'error',
            response.title
          );
          return Promise.reject(response);
        }
      }
    } catch (err) {
      const error: any = err || {};
      console.log('submit.error:', JSON.stringify(error));
      showNotification(
        error.message || 'request failed',
        error.type || 'warn',
        error.title || 'Update Error'
      );
      return Promise.reject(response);
    } finally {
      this.stopLoading();
    }
  };

  render = () => {
    const message = 'Please review all the data provided before saving.';
    const { classes, theme, ...extraProps } = this.props; //fmtUI, logo, title, switchFn,
    const { record, data, extras, setItem } = this.props;
    const { data: formdata } = data;
    const { loading } = this.state;
    console.log('SENDCARD.record: ', record);
    console.log('SENDCARD.formdata: ', formdata);
    return (
      <div>
        <BaseCard
          cardid={'SEND'}
          cardname={'Send'}
          cardtitle={'Review'}
          instruction={
            'Please review all the data provided before saving client'
          }
          step={5}
          buttons={['START', 'CANCEL', 'PREV', 'SEND', 'GUIDE']}
          doSubmit={this.doSubmit.bind(this)}
          {...extraProps}
        >
          <SendPanel
            id={'sendman'}
            //loaded={loaded}
            data={data}
            record={{ ...record, ...formdata }}
            extras={extras}
            loading={loading}
            setItem={setItem}
          />
        </BaseCard>
      </div>
    );
  };
}

SendCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SendCard);
