import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Theme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import { clone } from '../../../core/utils';
import { formatDate } from '../../../components/renderers';
import { create, isValid } from '../../../components/form';
import { extendedstyles } from '../../appstyles';

const extrastyles = (theme: Theme) => ({
  reqform: {
    //
  },
});
const styles = extendedstyles(extrastyles);

class SecurityPanel extends Component<any, any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props: any) {
    super(props);
    this.state = {
      source: {},
      preview: false,
      valid: false,
      count: 0,
    };
  }

  componentDidMount() {
    console.log(this.props?.data);
    this.props.setValid(this.state.valid);
  }

  formconfig = () => {
    //combo.inputLabelProps => cilp
    //const cbip = {InputLabelProps:{ shrink:true,}};
    const config = {
      service: {
        name: 'dataform',
        title: 'Data Form',
        eform: [
          //:nam,:ati,:aci,:ayi,:mbn,:cbu,:cbp,:cbc,:cba,:lci,:dtl,:sts'
          //{ id:'aci',label:'Category',type:'fa',req:1,ops:{ multiple:false,helperText:'Agent Category'},cp:{t:'bcs',s:'agentcategory'}},
          //{ id:'wsu',label:'WS User',type:'tf',col:2,req:0,ops:{helperText:'Enter Web Service User ID',pattern:"[a-zA-Z ]+"}},
          //{ id:'wsp',label:'WS Pass',type:'tf',col:2,req:0,ops:{helperText:'Enter Fullname',pattern:"[a-zA-Z ]+"}},
          { id: 'fnm', label: 'Firstname', type: 'tf', req: 1, ops: {} },
          { id: 'onm', label: 'Othernames', type: 'tf', req: 0, ops: {} },
          { id: 'snm', label: 'Surname', type: 'tf', req: 1, ops: {} },
          { id: 'unm', label: 'Username', type: 'tf', req: 1, ops: {} },
          {
            id: 'roi',
            label: 'Role',
            type: 'fa',

            req: 1,
            ops: {
              eti: this.props?.data?.data?.eti,
            },
            cp: { t: 'bcs', s: 'role' },
          },
          {
            id: 'ct1',
            label: 'Mobile',
            type: 'tf',
            req: 1,
            ops: { type: 'tel' },
          },
          { id: 'ct2', label: 'Contact', type: 'tf', req: 0, ops: {} },
        ],
      },
      create: true,
      actions: { add: 'Add' },
      panels: { add: 'simple' },
    };
    return config;
  };

  setValid = (data: any) => {
    const sconfig = this.formconfig();
    const { mode } = this.props;
    const valid = ['del', 'delete'].includes(mode)
      ? true
      : isValid(sconfig, data, mode);
    //console.log('valid:',valid);

    //const valid = (!!newdata.nam && !!newdata.agc && !!newdata.mbn && !!newdata.lci);
    //const valid = (!!data.wsu && !!data.cbu && !!data.cbc && !!data.cba);
    //console.log('valid:',valid);
    this.props.setValid(valid);
  };

  onChange = (name: any) => (value: any) => {
    const { data } = this.props;
    const newdata = { ...data.data, [name]: value };
    this.setValid(newdata);
    this.props.setItem('data', 'data', newdata);
  };

  makeform = () => {
    const config = this.formconfig();

    const { record, extras, classes } = this.props;
    const sconfig = clone(config);
    const sdata = sconfig.service;
    const mode = 'add';
    const formatters = { dob: formatDate };
    const onChange = this.onChange.bind(this);
    const form = create(
      mode,
      sdata,
      record,
      formatters,
      onChange,
      classes,
      extras
    );
    return form;
  };

  render() {
    //const {data,classes} = this.props;
    const form = this.makeform();
    return form;
  }
}

SecurityPanel.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(SecurityPanel);
